import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import Input from 'components/input/input';

const Email = () => {
  const [field, meta, helpers] = useField('email');
  const {t} = useTranslation();

  const isValid = !(meta.touched && meta.error);

  return (
    <Input
      {...field}
      color="gray"
      isAlt
      isValid={isValid}
      onChange={helpers.setValue}
      placeholder={t`Email`}
      type="email"
    />
  );
};

export default Email;
