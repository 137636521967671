import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {Grid, Placeholder, Preloader} from 'components';
import {useRequest} from 'common/hooks';
import * as AppEnv from 'app-env';

import Integration from './integration/integration';

const Integrations = () => {
  const [integrationList, setIntegrationList] =
    useState<AppEnv.OtherIntegration[]>();

  const {fetchIntegrationsOthers} = useRequest();
  const {t} = useTranslation();

  const getOtherIntegrationList = async () => {
    const res = await fetchIntegrationsOthers();
    setIntegrationList(res || []);
  };

  useEffect(() => {
    document.title = `${t`Integrations`} - Whatcrm Web`;
  }, []);

  useEffect(() => {
    getOtherIntegrationList();
  }, []);

  return (
    <>
      {integrationList ? (
        integrationList.length ? (
          <Grid>
            {integrationList.map(integration => (
              <Integration integration={integration} key={integration.id} />
            ))}
          </Grid>
        ) : (
          <Placeholder isMaxHeight title={t`No integrations`} />
        )
      ) : (
        <Preloader isMaxHeight />
      )}
    </>
  );
};

export default Integrations;
