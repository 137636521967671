import React from 'react';
import {useTranslation} from 'react-i18next';

import {getUrlParams} from 'common/actions';
import {Link, Placeholder, Text} from 'components';

const Error403 = () => {
  const {i18n, t} = useTranslation();
  const {crm} = getUrlParams();

  const support =
    i18n.resolvedLanguage === 'ru' && (crm == 'B24' || crm == 'TELPHGRAM')
      ? `https://whatcrm.net/${i18n.resolvedLanguage}#in-touch`
      : 'https://wa.me/79311070533';

  return (
    <Placeholder title="403 Forbidden">
      <Text color="light" size={14} textAlign="center">
        {t`Contact us on`}
        {' '}
        <Link href={support} isTargetBlank>
          {'WhatsApp'}
        </Link>{' '}
        {t`or`}
        {' '}
        <Link href="https://t.me/whatcrmbot" isTargetBlank>
          {'Telegram'}
        </Link>
        {'.'}
      </Text>
    </Placeholder>
  );
};

export default Error403;
