import {useTranslation} from 'react-i18next';
import * as AppEnv from 'app-env';

const useStatus = (integrator: AppEnv.Integrator) => {
  const {t} = useTranslation();

  switch (integrator.is_registration) {
    case 0:
      return t`Moderation`;
    case 1:
      return t`Registration allowed`;
    case 2:
      return t`Published`;
    default:
      return t`Unknown`;
  }
};

export default useStatus;
