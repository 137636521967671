import React, {useContext} from 'react';

interface CardContext {
  badge: string | undefined;
}

const CardContext = React.createContext<CardContext>({} as CardContext);

export const useCardContext = () => useContext(CardContext);
export default CardContext;
