import React from 'react';
import {useTranslation} from 'react-i18next';
import {formatPhoneNumber} from 'whatcrm-core';

import {Text} from 'components';
import {useInstanceContext} from '../../instance-context';

const Phone = () => {
  const {instance} = useInstanceContext();
  const {t} = useTranslation();

  return (
    <>
      {instance.phone && (
        <Text size={14}>
          {t`Phone number`}
          {': '}
          {formatPhoneNumber(instance.phone)}
        </Text>
      )}
    </>
  );
};

export default Phone;
