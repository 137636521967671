import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {PageBody, PageMain, Placeholder, Preloader} from 'components';
import * as AppEnv from 'app-env';

import Integrator from './integrator/integrator';

interface IntegrationMain {
  integrator: AppEnv.Integrator | null | undefined;
  setIntegrator: AppEnv.SetState<AppEnv.Integrator | null | undefined>;
}

const IntegrationMain = ({integrator, setIntegrator}: IntegrationMain) => {
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Integration`} - Whatcrm Web`;
  }, []);

  return (
    <PageBody>
      <PageMain>
        {typeof integrator == 'object' ? (
          integrator ? (
            <Integrator integrator={integrator} setIntegrator={setIntegrator} />
          ) : (
            <Placeholder isMaxHeight title={t`No integrator data`} />
          )
        ) : (
          <Preloader isMaxHeight />
        )}
      </PageMain>
    </PageBody>
  );
};

export default IntegrationMain;
