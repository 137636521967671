import React from 'react';
import cn from 'classnames';

import {Text, TextParams} from 'components';

interface SystemMessage {
  children: React.ReactNode;
  isAlt?: boolean;
  isDisabled?: boolean;
  onClick?: () => void;
}

const SystemMsg = ({children, isAlt, isDisabled, onClick}: SystemMessage) => {
  const className = cn('system-message__bubble', {
    'system-message__bubble_alt': isAlt,
    'system-message__bubble_clickable': onClick
  });

  const textParams: TextParams = {
    color: 'white',
    textAlign: !isAlt ? 'center' : undefined,
    size: 14
  };

  const text = <Text {...textParams}>{children}</Text>;

  return (
    <div className="system-message">
      {onClick ? (
        <button className={className} disabled={isDisabled} onClick={onClick}>
          {text}
        </button>
      ) : (
        <div className={className}>{text}</div>
      )}
    </div>
  );
};

export default SystemMsg;
