import React from 'react';
import cn from 'classnames';

import CardContext from './card-context';

interface Card {
  badge?: string;
  children: React.ReactNode;
  isActive?: boolean;
}

const Card = ({badge, children, isActive}: Card) => (
  <CardContext.Provider value={{badge}}>
    <div className={cn('card', {card_active: isActive})}>{children}</div>
  </CardContext.Provider>
);

export {default as CardBody} from './card-body/card-body';
export {default as CardHead} from './card-head/card-head';
export {default as CardMenu} from './card-menu/card-menu';

export default Card;
