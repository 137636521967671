import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Input} from 'components';

const Webhook = () => {
  const [field, , helpers] = useField<string>('webhook');
  const {t} = useTranslation();

  return (
    <Input
      {...field}
      onChange={helpers.setValue}
      placeholder={t`Webhook address`}
      style={{marginBottom: 16}}
      type="url"
    />
  );
};

export default Webhook;
