import React from 'react';
import {useField} from 'formik';

import {Attach as IAttach} from '../template-edit-env';
import {DropzoneAlt, Wrapper} from 'components';

import Attach from './attach/attach';

const Attachs = () => {
  const [field, , helpers] = useField<IAttach[]>('attachs');

  const handleChange = (files: File[]) => {
    const newValue = field.value.concat(files.map(file => ({file})));
    helpers.setValue(newValue);
  };

  return (
    <Wrapper direction="column" gap={8}>
      <DropzoneAlt multiple onChange={handleChange} />

      {field.value.length > 0 && (
        <div>
          {field.value.map((attach, i) => (
            <Attach attach={attach} index={i} key={i} />
          ))}
        </div>
      )}
    </Wrapper>
  );
};

export default Attachs;
