import React, {useEffect, useState} from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {DropzoneAlt} from 'components';
import * as AppEnv from 'app-env';
import {getFileUrl} from 'common/actions';

interface NewImage {
  integrator: AppEnv.Integrator;
}

const style: React.CSSProperties = {
  borderRadius: 6,
  height: 64,
  marginBottom: 16,
  objectFit: 'contain',
  width: 64
};

const NewImage = ({integrator}: NewImage) => {
  const [fileUrl, setFileUrl] = useState<string>();

  const [field, , helpers] = useField<File | undefined>('new_image');
  const {t} = useTranslation();

  const handleNewImage = async () => {
    if (!field.value) return;

    const res = await getFileUrl(field.value);
    setFileUrl(res);
  };

  useEffect(() => {
    handleNewImage();
  }, [field.value]);

  return (
    <>
      <img src={fileUrl || integrator.image} style={style} />

      <DropzoneAlt
        accept={{'image/*': []}}
        onChange={files => helpers.setValue(files[0])}
        placeholder={t`Logo`}
        style={{marginBottom: 16}}
      />
    </>
  );
};

export default NewImage;
