import React from 'react';

interface Prop {
  children: React.ReactNode;
}

const Prop = ({children}: Prop) => (
  <div className="prop-list__item">{children}</div>
);

export default Prop;
