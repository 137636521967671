import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Textarea} from 'components';

const Description = () => {
  const [field, , helpers] = useField<string>('description');
  const {t} = useTranslation();

  return (
    <Textarea
      {...field}
      onChange={helpers.setValue}
      placeholder={t`Integration description`}
      style={{marginBottom: 16}}
    />
  );
};

export default Description;
