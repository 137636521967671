const getFileUrl = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.readAsDataURL(file);
    fileReader.onerror = reject;

    fileReader.onloadend = () =>
      resolve(typeof fileReader.result == 'string' ? fileReader.result : '');
  });

export default getFileUrl;
