import React from 'react';

import {Text} from 'components';
import {useCardContext} from '../card-context';

interface CardHead {
  children: React.ReactNode;
  justifyContent?: 'space-between';
}

const CardHead = ({children, justifyContent}: CardHead) => {
  const {badge} = useCardContext();

  return (
    <div className="card__head" style={{justifyContent}}>
      {badge && (
        <span className="card__badge">
          <Text color="white" size={12}>
            {badge}
          </Text>
        </span>
      )}

      {children}
    </div>
  );
};

export default CardHead;
