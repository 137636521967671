import React, {useEffect, useRef, useState} from 'react';
import cn from 'classnames';

import {getTime} from 'common/actions';
import {MiniButton, Text, Wrapper} from 'components';
import {useConvertBytes} from 'common/hooks';

import Button from './button/button';
import Waveform from './waveform/waveform';

type PlaybackRate = 1 | 1.5 | 2;

export interface AudioPlayerProps {
  duration?: number;
  fileName?: string;
  isAlt?: boolean;
  onClick?: () => void;
  ref?: React.RefObject<HTMLDivElement>;
  size?: number;
  src: string | undefined;
  waveform?: number[];
}

const getPlaybackRate = (playbackRate: PlaybackRate): PlaybackRate => {
  if (playbackRate == 1) return 1.5;
  else if (playbackRate == 1.5) return 2;

  return 1;
};

const AudioPlayer = ({
  duration = 0,
  fileName,
  isAlt,
  onClick,
  ref,
  size,
  src,
  waveform = []
}: AudioPlayerProps) => {
  const [currentTime, setCurrentTime] = useState(0);
  const [playbackRate, setPlaybackRate] = useState<PlaybackRate>(1);
  const [timer, setTimer] = useState<NodeJS.Timeout>();

  const [isLoading, setIsLoading] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const audioRef = useRef<HTMLAudioElement>(null);
  const convertBytes = useConvertBytes();

  const handlePlay = () => {
    setIsPlaying(true);
    audioRef.current?.play();

    const newTimer = setInterval(() => {
      if (!audioRef.current?.currentTime) return;
      setCurrentTime(audioRef.current.currentTime);
    }, 10);

    setTimer(newTimer);
  };

  useEffect(() => {
    if (!src) return;
    audioRef.current?.setAttribute('src', src);

    if (!isLoading) return;

    handlePlay();
    setIsLoading(false);
  }, [src]);

  const handlePause = () => {
    setIsPlaying(false);
    audioRef.current?.pause();
    clearInterval(timer);
  };

  const handleButtonClick = () => {
    if (src) return isPlaying ? handlePause() : handlePlay();
    else if (onClick) onClick();

    setIsLoading(true);
  };

  const handleMiniButtonClick = () => {
    const newPlaybackRate = getPlaybackRate(playbackRate);
    setPlaybackRate(newPlaybackRate);
    if (audioRef.current) audioRef.current.playbackRate = newPlaybackRate;
  };

  const time = getTime(currentTime > 0 ? currentTime : duration);

  return (
    <div className={cn('audio-player', {'audio-player_alt': isAlt})} ref={ref}>
      <audio onPause={handlePause} ref={audioRef} />

      <Button
        isLoading={isLoading}
        isPlaying={isPlaying}
        onClick={handleButtonClick}
      />

      <div
        className={cn('audio-player__wrapper', {
          'audio-player__wrapper_gap': waveform.length
        })}
      >
        {waveform.length ? (
          <Waveform
            audioRef={audioRef}
            currentTime={currentTime}
            duration={duration}
            setCurrentTime={setCurrentTime}
            waveform={waveform}
          />
        ) : (
          fileName && (
            <Text size={16} isNoWrap>
              {fileName}
            </Text>
          )
        )}

        <Wrapper gap={4}>
          <MiniButton
            color={isAlt ? 'white' : undefined}
            onClick={handleMiniButtonClick}
          >
            {`${playbackRate}X`}
          </MiniButton>

          {time && (
            <Text className="audio-player__text" color="optional" size={14}>
              {time}
              {size != undefined && ` • ${convertBytes(size)}`}
            </Text>
          )}
        </Wrapper>
      </div>
    </div>
  );
};

export default AudioPlayer;
