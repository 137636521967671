import React from 'react';
import cn from 'classnames';

interface Title {
  children: React.ReactNode;
  isNoWrap?: boolean;
  isTextAlignCenter?: boolean;
  isUppercase?: boolean;
  isWhite?: boolean;
  style?: React.CSSProperties;
}

const Title = {
  H2: ({
    children,
    isNoWrap,
    isTextAlignCenter,
    isUppercase,
    isWhite,
    style
  }: Title) => (
    <h2
      className={cn('title title_h2', {
        title_nowrap: isNoWrap,
        title_uppercase: isUppercase,
        title_white: isWhite
      })}
      style={{...style, textAlign: isTextAlignCenter ? 'center' : undefined}}
    >
      {children}
    </h2>
  ),
  H3: ({
    children,
    isNoWrap,
    isTextAlignCenter,
    isUppercase,
    isWhite,
    style
  }: Title) => (
    <h3
      className={cn('title title_h3', {
        title_nowrap: isNoWrap,
        title_uppercase: isUppercase,
        title_white: isWhite
      })}
      style={{...style, textAlign: isTextAlignCenter ? 'center' : undefined}}
    >
      {children}
    </h3>
  )
};

export default Title;
