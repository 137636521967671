import React from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Body, Nav, Page, PageBody, PageMain} from 'components';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

import ProductsMain from './products-main/products-main';
import Telphin from './telphin/telphin';
import Integrations from './integrations/integrations';

const Products = () => {
  const {user} = useWorkspaceContext();

  const {t} = useTranslation();
  const location = useLocation();

  const {integration} = user;

  const options = [
    {label: t`Products`, value: '/products'}
    // {label: t`Telphin`, value: '/products/telphin'}
  ];

  if (integration.type == 'LK')
    options.push({label: t`Integrations`, value: '/products/integrations'});

  return (
    <Body>
      <Page>
        <Nav options={options} value={location.pathname} />

        <PageBody>
          <PageMain>
            <Routes>
              <Route path="/" element={<ProductsMain />} />
              <Route path="/integrations" element={<Integrations />} />
              <Route path="/telphin" element={<Telphin />} />
            </Routes>
          </PageMain>
        </PageBody>
      </Page>
    </Body>
  );
};

export default Products;
