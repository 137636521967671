import React from 'react';
import {useTranslation} from 'react-i18next';

import {Text} from 'components';
import {useInstanceContext} from '../../instance-context';

const Name = () => {
  const {instance} = useInstanceContext();
  const {t} = useTranslation();

  return (
    <>
      {instance.name && (
        <Text size={14}>
          {t`Name`}
          {': '}
          {instance.name}
        </Text>
      )}
    </>
  );
};

export default Name;
