import React from 'react';

interface PageContainer {
  children: React.ReactNode;
}

const PageContainer = ({children}: PageContainer) => (
  <div className="page__container">{children}</div>
);

export default PageContainer;
