import React from 'react';
import moment from 'moment';

import {TableData, TableRow, Icons} from 'components';
import * as AppEnv from 'app-env';

interface Client {
  client: AppEnv.IntegratorClient;
}

const Client = ({client}: Client) => (
  <TableRow>
    <TableData>{moment.unix(client.date_add).format('LL')}</TableData>
    <TableData>{client.domain}</TableData>

    <TableData>
      {client.is_deleted ? (
        <Icons.Check size={20} />
      ) : (
        <Icons.Close size={20} />
      )}
    </TableData>
  </TableRow>
);

export default Client;
