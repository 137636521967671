import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';
import moment from 'moment';
import update from 'immutability-helper';

import {Checkbox, Text, TimeInput, Wrapper} from 'components';
import * as AppEnv from 'app-env';

const WorkingHours = () => {
  const [field, , helpers] =
    useField<AppEnv.WidgetWorkingHours[]>('working_hours');

  const {t} = useTranslation();

  const handleIsWorking = (index: number, value: boolean) => {
    const newValue = update(field.value, {[index]: {isWorking: {$set: value}}});
    helpers.setValue(newValue);
  };

  const handleTime = (index: number, name: 'end' | 'start', value: string) => {
    const newValue = update(field.value, {[index]: {[name]: {$set: value}}});
    helpers.setValue(newValue);
  };

  const handleWeek = (value: boolean) => {
    const newValue = field.value.map(item => ({...item, isWorking: value}));
    helpers.setValue(newValue);
  };

  return (
    <Wrapper direction="column" gap={8}>
      <Checkbox
        label={t`Select all`}
        name="is-working"
        onChange={handleWeek}
        value={!field.value.some(item => !item.isWorking)}
      />

      {field.value.map((item, i) => (
        <Wrapper
          alignItems="center"
          gap={8}
          key={i}
          noWrap
          style={!i ? {order: 1} : undefined}
        >
          <Checkbox
            name={`is-working-${i}`}
            label={moment.weekdaysShort(i)}
            value={item.isWorking}
            onChange={value => handleIsWorking(i, value)}
            style={{width: 72}}
          />

          <TimeInput
            name={`start-${i}`}
            onChange={value => handleTime(i, 'start', value)}
            value={item.start}
          />

          <Text size={14}>{'–'}</Text>

          <TimeInput
            name={`end-${i}`}
            onChange={value => handleTime(i, 'end', value)}
            value={item.end}
          />
        </Wrapper>
      ))}
    </Wrapper>
  );
};

export default WorkingHours;
