import React from 'react';
import {FallbackProps} from 'react-error-boundary';

import {Button, Icons, Text, Title, Wrapper} from 'components';

const Fallback = ({error, resetErrorBoundary}: FallbackProps) => {
  const stack = error.stack.slice(0, error.stack.indexOf(' (http'));

  return (
    <Wrapper
      direction="column"
      justifyContent="center"
      style={{height: '100svh', padding: 20}}
    >
      <Title.H2 isTextAlignCenter style={{marginBottom: 8}}>
        {'Error'}
      </Title.H2>

      <Text
        color="light"
        textAlign="center"
        size={14}
        style={{marginBottom: 16}}
      >
        {stack}
        {'.'}
      </Text>

      <Wrapper justifyContent="center">
        <Button onClick={resetErrorBoundary}>
          <span>{'Refresh'}</span>
          <Icons.ArrowClockwise size={20} />
        </Button>
      </Wrapper>
    </Wrapper>
  );
};

export default Fallback;
