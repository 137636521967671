import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {Grid} from 'components';
import {Product} from '../components';

import {useTelphinProducts} from './hooks';

const Telphin = () => {
  const {t} = useTranslation();
  const telphinProducts = useTelphinProducts();

  useEffect(() => {
    document.title = `${t`Telphin products`} - Whatcrm Web`;
  }, []);

  return (
    <Grid>
      {telphinProducts.map((product, i) => (
        <Product key={i} product={product} />
      ))}
    </Grid>
  );
};

export default Telphin;
