import {useTranslation} from 'react-i18next';

const useBitrix24TopLevelDomain = () => {
  const {i18n} = useTranslation();

  if (['de', 'es', 'ru'].includes(i18n.resolvedLanguage || ''))
    return i18n.resolvedLanguage;
  else if (i18n.resolvedLanguage == 'pt') return 'com.br';

  return 'com';
};

export default useBitrix24TopLevelDomain;
