import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Confirm,
  Nav,
  Text,
  Wrapper
} from 'components';
import {Instances, Tariffs} from 'pages/workspace/components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import {useIcon, useMessenger} from './hooks/hooks';

type Tab = 'instances' | 'plans';

interface Option {
  label: string;
  value: Tab;
}

interface Partner {
  partner: AppEnv.Partner;
  setPartnerList: AppEnv.SetState<AppEnv.Partner[] | undefined>;
}

const Partner = ({partner, setPartnerList}: Partner) => {
  const {setNotification} = useAppContext();
  const {user} = useWorkspaceContext();

  const [instances, setInstances] = useState<AppEnv.Instances>();
  const [isConfirmActive, setIsConfirmActive] = useState(false);
  const [tab, setTab] = useState<Tab>('instances');
  const [tariffs, setTariffs] = useState<AppEnv.Tariffs | null>();

  const {fetchIntegrationsInstances, postPartnersDelete} = useRequest();
  const {t} = useTranslation();
  const icon = useIcon(partner.type_crm);
  const messenger = useMessenger(partner.type_crm);

  const deletePartner = async () => {
    const {integration} = user;

    const res = await postPartnersDelete(integration.id, {
      crm: partner.type_crm,
      domain: partner.domain
    });

    if (!res) return true;
    setNotification({text: t`Client has been deleted.`, title: partner.domain});

    setPartnerList(prevValue => {
      const index = prevValue?.findIndex(item => item.id == partner.id);
      if (index == undefined || index < 0) return prevValue;

      return upd(prevValue, {$splice: [[index, 1]]});
    });

    return true;
  };

  const handleClick = async (res: boolean) => {
    if (res) return deletePartner();

    setIsConfirmActive(false);
    return true;
  };

  const handleInstanceChange = (instance: AppEnv.Instance) =>
    setInstances(prevValue =>
      upd(prevValue, {[instance.id]: {$set: instance}})
    );

  const handleInstanceDelete = (id: number) =>
    setInstances(prevValue => upd(prevValue, {$unset: [id]}));

  const handleInstancesMount = async () => {
    if (instances) return;

    const res = await fetchIntegrationsInstances(partner.id);
    if (!res) setInstances({});

    const newInstances = res?.reduce((acc, instance) => {
      if (acc[instance.id]) return acc;
      acc[instance.id] = instance;

      return acc;
    }, {} as AppEnv.Instances);

    setInstances(newInstances);
  };

  const integration = {domain: partner.domain, type: partner.type_crm};

  const options: Option[] = [
    {label: t`Connections`, value: 'instances'},
    {label: t`Rate plans`, value: 'plans'}
  ];

  return (
    <Accordion onDelete={() => setIsConfirmActive(true)}>
      <AccordionSummary>
        <Wrapper alignItems="center" gap={8} noWrap>
          {icon}

          <div style={{minWidth: 0}}>
            <Text fontWeight={600} isNoWrap size={14}>
              {partner.domain}
            </Text>

            {messenger && (
              <Text color="optional" isNoWrap size={12}>
                {messenger}
              </Text>
            )}
          </div>
        </Wrapper>
      </AccordionSummary>

      <AccordionDetails>
        <Nav
          onChange={setTab}
          options={options}
          style={{marginBottom: 20}}
          value={tab}
        />

        {tab == 'instances' ? (
          <Instances
            instances={instances}
            integration={integration}
            isPartner
            onChange={handleInstanceChange}
            onDelete={handleInstanceDelete}
            onMount={handleInstancesMount}
          />
        ) : (
          <Tariffs
            instances={instances}
            integration={integration}
            isPartner
            setTariffs={setTariffs}
            tariffs={tariffs}
          />
        )}
      </AccordionDetails>

      <Confirm
        isActive={isConfirmActive}
        onClick={handleClick}
        title={t`Delete this client?`}
      />
    </Accordion>
  );
};

export default Partner;
