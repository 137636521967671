import React from 'react';
import cn from 'classnames';

interface Link {
  children: React.ReactNode;
  height?: 32;
  href: string;
  isTargetBlank?: boolean;
  isTextAlignCenter?: boolean;
}

const Link = ({
  children,
  height,
  href,
  isTargetBlank,
  isTextAlignCenter
}: Link) => (
  <a
    className={cn('link', {'link_text-align-center': isTextAlignCenter})}
    href={href}
    rel={isTargetBlank ? 'noreferrer' : undefined}
    style={{height}}
    target={isTargetBlank ? '_blank' : undefined}
  >
    {children}
  </a>
);

export default Link;
