import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

const useIsConnected = (integration: AppEnv.OtherIntegration) => {
  const {user} = useWorkspaceContext();

  const {integration: userIntegration} = user;
  const isConnected = integration.title == userIntegration.widget_code;

  return isConnected;
};

export default useIsConnected;
