import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Text, Input} from 'components';

const Body = () => {
  const {t} = useTranslation();
  const [field, meta, helpers] = useField<string>('body');

  const isValid = !(meta.error && meta.touched);

  return (
    <div>
      <Text
        size={16}
        style={{marginBottom: 4, paddingLeft: 12, textTransform: 'uppercase'}}
      >
        {t`Question`}
      </Text>

      <Input
        {...field}
        color="gray"
        isAlt
        isValid={isValid}
        onChange={helpers.setValue}
        placeholder={t`Ask a question`}
      />
    </div>
  );
};

export default Body;
