import React from 'react';
import {useTranslation} from 'react-i18next';

import {Button, Icons, Input, Text} from 'components';
import * as AppEnv from 'app-env';

interface Header {
  search: string;
  isUnreadFilter: boolean;
  setSearch: AppEnv.SetState<string>;
  setIsUnreadFilter: AppEnv.SetState<boolean>;
}

const Header = ({
  search,
  isUnreadFilter,
  setSearch,
  setIsUnreadFilter
}: Header) => {
  const {t} = useTranslation();

  return (
    <div className="dialogs__header">
      <div className="dialogs__header-wrapper">
        <div style={{width: 32}} />
        <Text size={16} textAlign="center">{t`All chats`}</Text>

        <Button
          color="transparent"
          tooltip={isUnreadFilter ? t`Show all` : t`Show unread`}
          onClick={() => setIsUnreadFilter(prevValue => !prevValue)}
        >
          <Icons.Filter color={isUnreadFilter ? 'green' : undefined} />
        </Button>

        {/* <Select
          options={[{label: t`Show unread`, value: 'unread'}]}
          value={isUnreadFilter ? 'unread' : undefined}
          isFilter
          onChange={() => setIsUnreadFilter(prevValue => !prevValue)}
        /> */}
      </div>

      <div className="dialogs__search">
        <Input
          autoCapitalize="off"
          color="light-gray"
          isAlt
          name="new-search"
          onChange={setSearch}
          type="search"
          value={search}
        />
      </div>
    </div>
  );
};

export default Header;
