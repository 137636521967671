import React from 'react';
import {useTranslation} from 'react-i18next';

import {Table, TableBody, TableData, TableHead} from 'components';
import * as AppEnv from 'app-env';

import Client from './client/client';

interface ClientList {
  clientList: AppEnv.IntegratorClient[];
}

const ClientList = ({clientList}: ClientList) => {
  const {t} = useTranslation();

  return (
    <Table>
      <TableHead>
        <TableData>{t`Connection date`}</TableData>
        <TableData>{t`Domain`}</TableData>
        <TableData>{t`Deleted`}</TableData>
      </TableHead>

      <TableBody>
        {clientList.map(client => (
          <Client client={client} key={client.id} />
        ))}
      </TableBody>
    </Table>
  );
};

export default ClientList;
