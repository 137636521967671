import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Input} from 'components';

const DescriptionShort = () => {
  const [field, meta, helpers] = useField<string>('description_short');
  const {t} = useTranslation();

  const isError = !!meta.error && meta.touched;

  return (
    <Input
      {...field}
      isValid={!isError}
      onChange={helpers.setValue}
      placeholder={t`Integration name`}
      style={{marginBottom: 16}}
    />
  );
};

export default DescriptionShort;
