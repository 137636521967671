import React from 'react';
import {Link, useLocation, useNavigate} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Button, Icons, LangSelect, Select, Text, Wrapper} from 'components';
import {useAppContext} from 'app-context';
import {useInstanceOptions} from 'common/hooks';
import {useWorkspaceContext} from '../workspace-context';
import * as AppEnv from 'app-env';

interface Header {
  isSidebarActive: boolean;
  setIsSidebarActive: AppEnv.SetState<boolean>;
}

const Header = ({isSidebarActive, setIsSidebarActive}: Header) => {
  const {isIframe} = useAppContext();
  const {activeInstances, instanceReferer, user} = useWorkspaceContext();

  const {t} = useTranslation();

  const instanceOptions = useInstanceOptions(activeInstances);
  const location = useLocation();
  const navigate = useNavigate();

  const match = location.pathname.match(/\/messenger\/(\d+)/);
  const [, instanceId]: Array<string | undefined> = match || [];

  const getTo = () => {
    if (instanceReferer) return `/messenger/${instanceReferer}`;

    const instanceId = location.pathname.split('/')[2];
    if (instanceId) return `/messenger/${instanceId}`;

    return '/messenger';
  };

  const handleChange = (value: number) => {
    if (value == parseInt(instanceId)) return;

    setIsSidebarActive(false);
    navigate(`/messenger/${value}`, {replace: isIframe});
  };

  const {integration, manager} = user;
  const isManagerNamed = manager.fullname != manager.email;
  const to = getTo();

  return (
    <header className="header">
      <Wrapper alignItems="center" justifyContent="space-between" noWrap>
        <div className="header__wrapper">
          <Wrapper alignItems="center" gap={8}>
            <Button
              color="transparent"
              state={isSidebarActive ? 'active' : undefined}
              onClick={() => setIsSidebarActive(prevValue => !prevValue)}
            >
              <Icons.ListBurgerMenu />
            </Button>

            <div className="header__manager">
              <Text size={!isManagerNamed ? 16 : 14}>
                {process.env.REACT_APP_TYPE == 'app'
                  ? integration.domain
                  : manager.fullname}
              </Text>

              {isManagerNamed && <Text size={12}>{manager.email}</Text>}
            </div>
          </Wrapper>

          {instanceOptions.length > 0 && (
            <Select
              options={instanceOptions}
              placeholder={t`Connection`}
              value={parseInt(instanceId)}
              isAlt
              onChange={handleChange}
            />
          )}

          {!isIframe && <LangSelect />}
        </div>

        {location.pathname == to ? (
          <div className="logo" />
        ) : (
          <Link
            onClick={() => setIsSidebarActive(false)}
            replace={isIframe}
            to={to}
          >
            <div className="logo" />
          </Link>
        )}
      </Wrapper>
    </header>
  );
};

export default Header;
