import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import upd from 'immutability-helper';

import {
  Button,
  Card,
  CardBody,
  CardHead,
  CardMenu,
  Icons,
  Text,
  Title
} from 'components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import {useIsConnected} from './hooks';

interface Integration {
  integration: AppEnv.OtherIntegration;
}

const style: React.CSSProperties = {borderRadius: 4, height: 32, width: 32};

const Integration = ({integration}: Integration) => {
  const {setNotification} = useAppContext();
  const {setUser, user} = useWorkspaceContext();

  const [isLoad, setIsLoad] = useState(false);

  const {postIntegrationsUpdate} = useRequest();
  const {t} = useTranslation();
  const isConnected = useIsConnected(integration);

  const handleClick = async () => {
    const {integration: userIntegration} = user;
    setIsLoad(true);

    const res = await postIntegrationsUpdate(userIntegration.id, {
      widget_code: isConnected ? '' : integration.title
    });

    setIsLoad(false);
    if (!res) return;

    setNotification({
      text: res.widget_code
        ? t`Integration has been connected.`
        : t`Integration has been disconnected.`,
      title: integration.description_short
    });

    setUser(prevValue =>
      upd(prevValue, {
        integration: {widget_code: {$set: res.widget_code || ''}}
      })
    );
  };

  const {integration: userIntegration} = user;

  return (
    <Card badge={isConnected ? t`connected` : undefined} isActive={isLoad}>
      <CardHead>
        <img src={integration.image} style={style} />
        <Title.H3 style={{marginTop: 6}}>
          {integration.description_short}
        </Title.H3>
      </CardHead>

      <CardBody>
        <Text size={14}>{integration.description}</Text>
      </CardBody>

      {!userIntegration.is_integration && (
        <CardMenu>
          <Button color="transparent" isDisabled={isLoad} onClick={handleClick}>
            {isLoad ? (
              <Icons.CircleNotch size={20} />
            ) : isConnected ? (
              <Icons.LinkSimpleBreak size={20} />
            ) : (
              <Icons.LinkSimple size={20} />
            )}

            <span>{isConnected ? t`Disconnect` : t`Connect`}</span>
          </Button>
        </CardMenu>
      )}
    </Card>
  );
};

export default Integration;
