import {useTranslation} from 'react-i18next';

import {getBaseUrl} from 'common/actions';
import {Product} from '../../../products-env';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import useBitrix24TopLevelDomain from '../../../products-main/hooks/use-products/use-bitrix24-top-level-domain/use-bitrix24-top-level-domain';

const useTelphinProducts = () => {
  const {user} = useWorkspaceContext();

  const {i18n, t} = useTranslation();
  const bitrix24TopLevelDomain = useBitrix24TopLevelDomain();

  const {integration} = user;
  const {kommoUrl} = getBaseUrl();

  const isBitrix24 =
    integration.type == 'B24' || integration.type == 'TELPHGRAM';

  const isKommo =
    integration.type == 'AMO' ||
    integration.type == 'AVITO' ||
    integration.type == 'TELEGRAM';

  const isPipedrive = integration.type == 'PIPEDRIVE';

  const telphinProducts: Product[] = [
    {
      description_short: t`Integration with the Telphin.Office ATS`,
      description: `${t`Integration of Telphin.Office ATS and`} ${t`Kommo`} ${t`will build a competent work with calls.`}`,
      image:
        i18n.resolvedLanguage == 'ru'
          ? '/static/img/products/telphin.svg'
          : '/static/img/products/telphin_en.svg',
      label: t`Install`,
      link: `${kommoUrl}/oauth/?state=state&mode=popup&client_id=78fe1083-e73a-4052-bf06-ca73e109bbb9`,
      skip: isBitrix24 || isPipedrive
    },
    {
      description_short: t`Integration with the Telphin.Office ATS`,
      description: t`Integration of Telphin.Office ATS and Bitrix24 will build a competent work with calls.`,
      image:
        i18n.resolvedLanguage == 'ru'
          ? '/static/img/products/telphin.svg'
          : '/static/img/products/telphin_en.svg',
      label: t`Install`,
      link: `https://www.bitrix24.${bitrix24TopLevelDomain}/apps/app/telphin.integration/`,
      skip: isKommo || isPipedrive
    },
    {
      description_short: t`Telme — always on line`,
      description: t`Receive and make calls from Telegram to any landline or mobile number. Save on communication. Forget about roaming, extra applications and extra SIM cards.`,
      image: '/static/img/products/telme.svg',
      label: t`Connect`,
      link: 'https://t.me/Telmebot'
    }
  ].filter(item => !item.skip);

  return telphinProducts;
};

export default useTelphinProducts;
