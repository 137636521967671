import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

import {Grid} from 'components';
import {Product} from '../components';

import {useProducts} from './hooks';

const ProductsMain = () => {
  const {t} = useTranslation();
  const products = useProducts();

  useEffect(() => {
    document.title = `${t`Products`} - Whatcrm Web`;
  }, []);

  return (
    <Grid>
      {products.map((product, i) => (
        <Product key={i} product={product} />
      ))}
    </Grid>
  );
};

export default ProductsMain;
