import React, {useState} from 'react';
import {formatPhoneNumber, isolateNumbers} from 'whatcrm-core';
// import {useTranslation} from 'react-i18next';
import moment from 'moment';
import upd from 'immutability-helper';

import {
  Button,
  Confirm,
  Link,
  PageBody,
  PageFooter,
  PageMain,
  Text,
  Title
} from 'components';
import {useAppContext} from 'app-context';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';

const PartnerInfo = () => {
  const {setNotification} = useAppContext();
  const {setUser, user} = useWorkspaceContext();

  const [isConfirm, setIsConfirm] = useState(false);

  const {fetchPartnersCancel} = useRequest();
  // const {t} = useTranslation();

  const cancelPartner = async () => {
    const {integration} = user;

    const res = await fetchPartnersCancel(integration.id);
    if (!res) return true;

    setNotification({title: `You have ended the partnership.`});
    setUser(prevValue => upd(prevValue, {partner: {$set: null}}));

    return true;
  };

  const handleClick = (res: boolean) => {
    if (res) return cancelPartner();
    setIsConfirm(false);
  };

  const {partner = undefined} = 'partner' in user ? user : {};

  return (
    <>
      <PageBody>
        <PageMain>
          <Title.H2 style={{marginBottom: 8}}>{partner?.domain}</Title.H2>

          <Text color="secondary" size={14} style={{marginBottom: 16}}>
            {`Partnership`}{' '}
            {partner?.title && (
              <>
                {`with`} {partner.title}{' '}
              </>
            )}
            {partner?.date_add && (
              <>
                {`since`} {moment.unix(partner.date_add).format('L')}
              </>
            )}
          </Text>

          {partner?.phone && (
            <Text size={14}>
              <Link href={`tel:${isolateNumbers(partner.phone)}`}>
                {formatPhoneNumber(partner.phone)}
              </Link>
            </Text>
          )}

          {partner?.email && (
            <Text size={14}>
              <Link href={`mailto:${partner.email}`}>{partner.email}</Link>
            </Text>
          )}
        </PageMain>
      </PageBody>

      <PageFooter>
        {partner && (
          <Button onClick={() => setIsConfirm(true)}>
            {`End partnership`}
          </Button>
        )}
      </PageFooter>

      <Confirm
        isActive={isConfirm}
        onClick={handleClick}
        title={`End the partnership?`}
      />
    </>
  );
};

export default PartnerInfo;
