import React from 'react';
import {useField} from 'formik';
import update from 'immutability-helper';

import {Button, Icons, Input, Wrapper} from 'components';
import * as AppEnv from 'app-env';

interface Social {
  index: number;
  social: AppEnv.WidgetSocial;
}

const icons = {
  facebook: <Icons.Facebook />,
  instagram: <Icons.Instagram />,
  linkedin: <Icons.LinkedIn />,
  telegram: <Icons.Telegram />,
  twitter: <Icons.Twitter />,
  whatsapp: <Icons.WhatsApp />
};

const placeholders = {
  facebook: 'https://facebook.com/',
  instagram: 'https://instagram.com/',
  linkedin: 'https://linkedin.com/',
  telegram: 'https://t.me/',
  twitter: 'https://x.com/',
  whatsapp: 'https://wa.me/'
};

const Social = ({index, social}: Social) => {
  const [field, , helpers] = useField<AppEnv.WidgetSocial[]>('socials');

  const handleChange = (value: string) =>
    helpers.setValue(update(field.value, {[index]: {url: {$set: value}}}));

  const handleClick = () => {
    const newValue = update(field.value, {$splice: [[index, 1]]});
    helpers.setValue(newValue);
  };

  return (
    <Wrapper alignItems="center" gap={8} noWrap>
      {icons[social.version]}

      <Input
        color="gray"
        isAlt
        name={`socials-${index}`}
        onChange={handleChange}
        placeholder={placeholders[social.version]}
        value={social?.url}
      />

      <Button color="transparent" onClick={handleClick}>
        <Icons.TrashSimple color="light" size={24} />
      </Button>
    </Wrapper>
  );
};

export default Social;
