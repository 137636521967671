import React, {useEffect, useState} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';
import {useTranslation} from 'react-i18next';

import {Body, Nav, Page} from 'components';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import IntegrationMain from './integration-main/integration-main';
import Settings from './settings/settings';
import Users from './users/users';

const Integration = () => {
  const {user} = useWorkspaceContext();
  const [integrator, setIntegrator] = useState<AppEnv.Integrator | null>();

  const {fetchIntegratorsIndex} = useRequest();
  const {t} = useTranslation();
  const location = useLocation();

  const getIntegrator = async () => {
    const {integration} = user;

    const res = await fetchIntegratorsIndex(integration.id);
    setIntegrator(res || null);
  };

  useEffect(() => {
    getIntegrator();
  }, []);

  const options = [
    {label: t`Integration`, value: '/integration'},
    {label: t`Settings`, value: '/integration/settings'},
    {label: t`Users`, value: '/integration/users'}
  ];

  return (
    <Body>
      <Page>
        <Nav options={options} value={location.pathname} />

        <Routes>
          <Route
            path="/"
            element={
              <IntegrationMain
                integrator={integrator}
                setIntegrator={setIntegrator}
              />
            }
          />

          <Route
            path="/settings"
            element={
              <Settings integrator={integrator} setIntegrator={setIntegrator} />
            }
          />

          <Route path="/users" element={<Users />} />
        </Routes>
      </Page>
    </Body>
  );
};

export default Integration;
