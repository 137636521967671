import React from 'react';
import cn from 'classnames';

interface TableData {
  children?: React.ReactNode;
  colSpan?: number;
  isIcon?: boolean;
}

const TableData = ({children, colSpan, isIcon}: TableData) => (
  <td
    className={cn('table__data', {table__data_icon: isIcon})}
    colSpan={colSpan}
  >
    {children}
  </td>
);

export default TableData;
