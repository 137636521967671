import React from 'react';
import {useField} from 'formik';
import {useTranslation} from 'react-i18next';

import {Input, Text, Wrapper} from 'components';

const RightMobile = () => {
  const [field, , helpers] = useField<number | null>('right_mobile');
  const {t} = useTranslation();

  return (
    <div>
      <Text color="optional" size={12} style={{marginBottom: 4}}>
        {t`Margin right`}
      </Text>

      <Wrapper alignItems="center" gap={8} noWrap>
        <Input
          {...field}
          color="gray"
          isAlt
          onChange={value => helpers.setValue(value ? parseInt(value) : null)}
          type="number"
          value={typeof field.value == 'number' ? field.value : ''}
          width={80}
        />

        <Text size={14}>{`px`}</Text>
      </Wrapper>
    </div>
  );
};

export default RightMobile;
