import {getDialogId} from 'common/actions';
import * as AppEnv from 'app-env';

const getIsDialogBlacklisted = (user: AppEnv.User, dialog: AppEnv.Dialog) => {
  if (!('blacklist' in user)) return false;

  const id = getDialogId(dialog)?.toString();
  if (!id) return false;

  const isBlacklisted = user.blacklist?.some(
    blacklisted => blacklisted.phone == id
  );

  return isBlacklisted;
};

export default getIsDialogBlacklisted;
