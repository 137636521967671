import React, {forwardRef} from 'react';
import cn from 'classnames';
import ReactTextareaAutosize from 'react-textarea-autosize';

interface Textarea {
  isAlt?: boolean;
  isError?: boolean;
  name: string;
  onChange: (arg: string) => void;
  onKeyDown?: React.KeyboardEventHandler<HTMLTextAreaElement>;
  placeholder: string;
  style?: React.CSSProperties;
  value: string;
}

const Textarea = forwardRef<HTMLTextAreaElement, Textarea>(
  (
    {isAlt, isError, name, onChange, onKeyDown, placeholder, style, value},
    ref
  ) => (
    <ReactTextareaAutosize
      className={cn('textarea', {textarea_alt: isAlt, textarea_error: isError})}
      name={name}
      onChange={e => onChange(e.target.value)}
      onKeyDown={onKeyDown}
      placeholder={placeholder}
      ref={ref}
      style={style as any} // eslint-disable-line
      value={value}
    />
  )
);

Textarea.displayName = 'Textarea';
export default Textarea;
