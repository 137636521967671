import React from 'react';

import {Text} from 'components';
import * as AppEnv from 'app-env';

interface Row {
  row: AppEnv.WhatsAppListRow;
}

const Row = ({row}: Row) => (
  <>
    <Text size={16} fontWeight={600}>
      {row.title}
    </Text>

    {row.description && (
      <Text color="light" fontWeight={600} size={14}>
        {row.description}
      </Text>
    )}
  </>
);

export default Row;
