import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {
  Placeholder,
  Preloader,
  Table,
  TableBody,
  TableData,
  TableHead
} from 'components';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import Subscription from './subscription/subscription';

const Subscriptions = () => {
  const {user} = useWorkspaceContext();

  const [subscriptionList, setSubscriptionList] =
    useState<AppEnv.Subscription[]>();

  const {fetchSubscriptions} = useRequest();
  const {t} = useTranslation();

  const getPaymentList = async () => {
    const {integration} = user;

    const res = await fetchSubscriptions(integration.id);
    setSubscriptionList(res || []);
  };

  useEffect(() => {
    document.title = `${t`Subscriptions`} - Whatcrm Web`;
  }, []);

  useEffect(() => {
    getPaymentList();
  }, []);

  const {integration} = user;

  return (
    <>
      {subscriptionList ? (
        subscriptionList.length ? (
          <Table>
            <TableHead>
              <TableData>
                {t`Start date`}
                {` (UTC+3)`}
              </TableData>

              <TableData>{t`Period`}</TableData>
              <TableData>{t`Rate plan`}</TableData>
              <TableData>{t`Payment gateway`}</TableData>

              {integration.is_partner > 0 && <TableData>{t`Domain`}</TableData>}
            </TableHead>

            <TableBody>
              {subscriptionList.map((subscription, i) => (
                <Subscription key={i} subscription={subscription} />
              ))}
            </TableBody>
          </Table>
        ) : (
          <Placeholder isMaxHeight title={t`No subscriptions`} />
        )
      ) : (
        <Preloader isMaxHeight />
      )}
    </>
  );
};

export default Subscriptions;
