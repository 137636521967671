import React from 'react';

interface PropList {
  children: React.ReactNode;
}

const PropList = ({children}: PropList) => (
  <div className="prop-list">{children}</div>
);

export {default as Prop} from './prop/prop';
export default PropList;
