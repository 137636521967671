import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {PageBody, PageMain, Placeholder, Preloader} from 'components';
import {useRequest} from 'common/hooks';
import {useWorkspaceContext} from 'pages/workspace/workspace-context';
import * as AppEnv from 'app-env';

import ClientList from './client-list/client-list';

const Users = () => {
  const {user} = useWorkspaceContext();
  const [clientList, setClientList] = useState<AppEnv.IntegratorClient[]>();

  const {fetchIntegratorsClients} = useRequest();
  const {t} = useTranslation();

  useEffect(() => {
    document.title = `${t`Integration users`} - Whatcrm Web`;
  }, []);

  const getClients = async () => {
    const {integration} = user;
    const res = await fetchIntegratorsClients(integration.id);
    setClientList(res || []);
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    <PageBody>
      <PageMain>
        {clientList ? (
          clientList.length ? (
            <ClientList clientList={clientList} />
          ) : (
            <Placeholder isMaxHeight title={t`No users`} />
          )
        ) : (
          <Preloader isMaxHeight />
        )}
      </PageMain>
    </PageBody>
  );
};

export default Users;
